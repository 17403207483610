export default function () {
    const $registrationModalNode = document.getElementById('registration-modal');
    const $loginModalNode = document.getElementById('login-modal');
    const $resetPasswordModalNode = document.getElementById('reset-password-modal');

    if ($registrationModalNode == null || $loginModalNode == null || $resetPasswordModalNode == null) {
        return false;
    }

    const $registrationModal = new Modal($registrationModalNode);
    const $loginModal = new Modal($loginModalNode);
    const $resetPasswordModal = new Modal($resetPasswordModalNode);

    $('.app-show-login-modal').on('click', function (e) {
        e.preventDefault();
        $registrationModal.hide();
        $resetPasswordModal.hide();
        $loginModal.show();
    });

    $('.app-show-registration-modal').on('click', function (e) {
        e.preventDefault();
        $loginModal.hide();
        $resetPasswordModal.hide();
        $registrationModal.show();
    });

    $('.app-user-logout').on('click', function (e) {
        e.preventDefault();
        $.request('onShopUserLogOut', {
            method: 'POST'
        })
            .then(() => {
                location.reload();
            });
    });

    /**
     * Клик по кнопке (Забыли пароль)
     */
    $('.app-reset-password-link').on('click', function (e) {
        e.preventDefault();
        $loginModal.hide();
        $resetPasswordModal.hide();
        $resetPasswordModal.show();
    });
}