export default function () {
    const $filtersBlock = $('#app-shop-filters');
    if ($filtersBlock.length === 0) {
        return;
    }

    $filtersBlock.find('.app-shop-filters__range')
        .each(function () {
            const $container = $(this).closest('.app-shop-filters__range-wrapper');
            const $minInput = $container.find('.app-shop-filters__range-min-value');
            const $maxInput = $container.find('.app-shop-filters__range-max-value');
            $(this).ionRangeSlider({
                type: "double",
                min: $(this).data('min-value'),
                max: $(this).data('max-value'),
                hide_min_max: true,
                hide_from_to: true,
                onChange(data) {
                    $minInput.val(data.from);
                    $maxInput.val(data.to);
                }
            });
        });

    $filtersBlock.find('.app-shop-products-filter-reset').on('click', function (e) {
        e.preventDefault();
        location.href = location.pathname;
    })
}