export default function (response) {
    return new Promise((resolve) => {
        $.request('onMiniCartFetchProducts')
            .then(() => {
                if (window.EventBus !== undefined) {
                    window.EventBus.$emit('onMiniCartUpdated', response);
                }
                resolve();
            });
    });
}