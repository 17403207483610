export default function () {
    /**
     * Клик по селектору с выбором количества товара
     */
    $(document).on('click', '.app-product-count-selector button', function (e) {
        e.preventDefault();
        const $root = $(this).closest('.app-product-count-selector');
        const sign = parseInt($(this).data('sign'), 10);
        const $count = $root.find('input');
        $count
            .val(parseInt($count.val(), 10) + sign)
            .trigger('change');
    });

    $(document).on('change', '.app-product-count-selector input', function () {
        if ($(this).val() < 1) {
            $(this).val(1);
        }
        // для кнопки купить поменяем количество товара
        $('[data-product-id=' + $(this).closest('.app-product-count-selector').data('product-id') + ']')
            .data('product-count', $(this).val());
    });
}