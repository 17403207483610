export default function (productId) {
    return new Promise((resolve, reject) => {
        $.request('onShopCartCheckModifications', {
            method: 'POST',
            data: {
                product_id: productId
            }
        }).then((response) => {
            if (response.success) {
                resolve(response.data.modifications);
            } else {
                reject(response.text);
            }
        });
    });
}