/**
 *
 * @param {number} productId
 * @param {{phone: string, name: string, comment: string, email: string | null}} user
 * @returns {Promise<void>}
 */
export default function (productId, user) {
    return new Promise((resolve, reject) => {
        return $.request('onShopCreateOrder', {
            method: 'POST',
            loading: $.oc.stripeLoadIndicator,
            data: {
                product_id: productId,

                user_name: user.name,
                user_phone: user.phone,
                user_email: user.email,
                user_comment: user.comment
            }
        }).then((response) => {
            if (response.success === true) {
                resolve();
            } else {
                reject(response.text);
            }
        });
    });
}