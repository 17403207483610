import $ from 'jquery';
import { Modal } from 'bootstrap'

$(function () {
    const $modal = document.querySelector('#callback-modal');
    const modal = new Modal($modal);

    $('.app-show-callback-modal').on('click', function (e) {
        e.preventDefault();
        $('#callbackModal_forms_flash').html('');
        modal.show();
    });
});