import './utils/scrolling';
import './utils/sliders';
import './utils/menu';
import './utils/callback';
import './utils/privacypolicy';
import $ from 'jquery';
import Inputmask from "inputmask";
import { Modal } from 'bootstrap';

window.$ = $;
window.jQuery = $;
window.Modal = Modal;

import { productApp } from "../../../../../../plugins/infocity/shop/assets/app";

require('@fancyapps/fancybox');
require('ion-rangeslider/js/ion.rangeSlider.min');

$(function () {
    const mask = new Inputmask('+9 (999) 999-99-99');
    mask.mask(document.querySelectorAll('.maskedinput-phone'));

    productApp();
});

window.onload = function () {
    document.body.classList.add('loaded_hiding');
    window.setTimeout(function () {
        document.body.classList.add('loaded');
        document.body.classList.remove('loaded_hiding');
    }, 500);
}