import {Swiper, Pagination, Navigation, Grid, Thumbs, Autoplay} from "swiper";

new Swiper(".mainSwiper", {
    modules: [Pagination, Navigation, Autoplay],
    slidesPerView: 1,
    spaceBetween: 30,
    autoplay: {
        delay: 4000,
        disableOnInteraction: false,
    },
    loop: true,
    pagination: {
        el: ".swiper-pagination",
        clickable: true,
    },
    navigation: {
        nextEl: ".main-swiper-button-next",
        prevEl: ".main-swiper-button-prev",
    },
});

new Swiper(".main-catalogSwiper", {
    modules: [Pagination, Navigation, Grid],
    slidesPerView: 5,
    spaceBetween: 40,
    loop: true,
    loopFillGroupWithBlank: true,
    navigation: {
        nextEl: ".main-catalog-swiper-button-next",
        prevEl: ".main-catalog-swiper-button-prev",
    },
    breakpoints: {
        300: {
            slidesPerView: 2,
            spaceBetween: 10,
        },
        768: {
            slidesPerView: 3,
            spaceBetween: 10,
        },
        1200: {
            slidesPerView: 4,
            spaceBetween: 20,
        },
        1400: {
            slidesPerView: 4,
            spaceBetween: 30,
        },
        1600: {
            slidesPerView: 5,
            spaceBetween: 40,
        },
    },
});

const swiper = new Swiper(".cartSwiper", {
    modules: [Navigation, Grid],
    loop: true,
    spaceBetween: 12,
    slidesPerView: 4,
    freeMode: true,
    watchSlidesProgress: true,
    navigation: {
        nextEl: ".cart-swiper-button-next",
        prevEl: ".cart-swiper-button-prev",
    },
    breakpoints: {
        320: {
            slidesPerView: 3,
            spaceBetween: 12,
        },
    },
});
new Swiper(".cartSwiper2", {
    modules: [Thumbs],
    loop: true,
    spaceBetween: 10,
    thumbs: {
        swiper: swiper,
    },
});

new Swiper(".watchedSwiper", {
    modules: [Navigation, Grid],
    slidesPerView: 6,
    spaceBetween: 30,
    loop: true,
    navigation: {
        nextEl: ".watched-swiper-button-next",
        prevEl: ".watched-swiper-button-prev",
    },
    breakpoints: {
        300: {
            slidesPerView: 2,
            spaceBetween: 10,
        },
        768: {
            slidesPerView: 3,
            spaceBetween: 10,
        },
        1200: {
            slidesPerView: 4,
            spaceBetween: 20,
        },
        1400: {
            slidesPerView: 5,
            spaceBetween: 30,
        },
        1600: {
            slidesPerView: 6,
            spaceBetween: 30,
        },
    },
});
