import $ from 'jquery';

$(function () {
    $('.app-scroll-to-block').on('click', function (e) {
        e.preventDefault();

        $("html, body").animate({
            scrollTop: $($(this).data('el')).offset().top
        }, 1000);
    });
});

$(document).ready(function(){
    $(window).scroll(function(){
        if($(window).scrollTop()>120){
            $('.link-to-top').fadeIn(700)
        }else{
            $('.link-to-top').fadeOut(700)
        }
    });
});