import $ from 'jquery';

$(function () {
    $('.app-accept-cookies').on('click', function (e) {
        $.request('onAcceptCookies')
            .then(() => {
                location.reload();
            })
    });

    $('.app-no-accept-cookies').on('click', function (e) {
        $.request('onNoAcceptCookies')
            .then(() => {
                location.reload();
            })
    });
});