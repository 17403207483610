/**
 * Функция пересчитывает общую стоимость (стоимость товаров + стоимость доставки)
 */
import price from "../utils/price";

export default function () {
    const $form = $('#app-cart-order-form');

    const $totalSumBlock = $('#app-cart-total-sum');
    const $deliverySumBlock = $('#app-delivery-total-sum');
    const $deliverySumInput = $form.find('input[name=delivery_price]');

    const deliveryPrice = parseInt($deliverySumInput.val(), 10);

    // пересчитаем итого
    $deliverySumBlock.html(price(deliveryPrice));

    $totalSumBlock.html(
        price(parseInt($totalSumBlock.data('sum'), 10) + deliveryPrice)
    );
}