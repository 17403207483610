import cartChangeProductCount from "../services/cart-change-product-count";
import recalculateTotalPrice from "../services/recalculate-total-price";

export default function () {
    const $form = $('#app-cart-order-form');

    function resetPaymentVariants($form) {
        $form.find('.app-cart-payment-item').each(function () {
            $(this).parent().addClass('d-none');
            $(this).removeClass('active');
        });
        $form.find('input[name=payment_id]').val(0);
    }

    function showNeedPaymentVariants(deliveryCode) {
        // скроем варианты оплат, которых нет
        resetPaymentVariants($form);
        const $scope = $('.app-cart-delivery-item[data-delivery-code="' + deliveryCode + '"]');

        // покажем только те варианты оплат, которые есть для данной доставки
        $scope.data('payment-variants').forEach((paymentId) => {
            $('.app-cart-payment-item[data-payment-id=' + paymentId + ']').parent().removeClass('d-none');
        });
        $('.app-cart-payment-item[data-payment-id=' + $scope.data('payment-variants')[0] + ']').trigger('click');
    }


    let deliveryModal = null;
    const onShoppingCartUpdatedCallbacks = [];
    window.onShoppingCartUpdated = function (callback) {
        onShoppingCartUpdatedCallbacks.push(callback);
    }

    window.onSelectDelivery = function (deliveryCode, deliveryAddress, deliveryPrice) {
        $('#app-cart-order-form').find('input[name=user_address_string]').val(deliveryAddress);
        $('#app-cart-order-form').find('input[name=delivery_price]').val(deliveryPrice);

        $('.app-cart-delivery-item').removeClass('active');
        $('.app-cart-delivery-item[data-delivery-code="' + deliveryCode + '"]').addClass('active');

        if (deliveryModal != null) {
            deliveryModal.hide();
        }
        showNeedPaymentVariants(deliveryCode);
        recalculateTotalPrice();
    }

    const $cartDeliveryBlock = $('.app-cart-address-block');

    $('.app-cart-delivery-block .app-cart-delivery-item').on('click', function (e) {
        e.preventDefault();
        e.stopPropagation();
        const $scope = $(this);
        $('.app-cart-delivery-block .app-cart-delivery-item').removeClass('active');
        $scope.addClass('active');
        $cartDeliveryBlock.addClass('d-none');
        if ($scope.data('need-address')) {
            $cartDeliveryBlock.removeClass('d-none');
        }

        $('.app-cart-payments-block-wrapper').removeClass('d-none');
        $('.app-cart-delivery-block-text__item').addClass('d-none');
        $('.app-cart-delivery-block-text__item[data-delivery-id=' + $scope.data('delivery-id') + ']').removeClass('d-none');
        $form.find('input[name=delivery_id]').val($scope.data('delivery-id'));
        $form.find('input[name=user_address_string]').val('');
        $form.find('input[name=delivery_price]').val(0);

        showNeedPaymentVariants($scope.data('delivery-code'));

        // если для выбранного способа доставки есть модальное окно, которое требуется показать - покажем его
        const $modal = document.querySelector('#delivery-modal-' + $scope.data('delivery-code'));
        if ($modal !== null) {
            deliveryModal = new Modal($modal);
            deliveryModal.show();
        }

        recalculateTotalPrice();
    });

    $('.app-cart-payments-block .app-cart-payment-item ').on('click', function (e) {
        e.preventDefault();
        const $scope = $(this);
        $('.app-cart-payments-block .app-cart-payment-item').removeClass('active');
        $scope.addClass('active');
        $form.find('input[name=payment_id]').val($(this).data('payment-id'));
    });

    $(document).on('change', '.app-cart-product-count', function () {
        const $scope = $(this);
        cartChangeProductCount(
            $scope.data('product-id'),
            $scope.data('modification-id'),
            $scope.val()
        ).then(() => {
            onShoppingCartUpdatedCallbacks.forEach((callback) => {
                callback();
            });
            onSelectDelivery('self', '', 0);
        });
    });

    $('.app-cart-delivery-item').first().trigger('click');
}