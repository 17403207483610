export default function (productId, modificationId, count) {
    return $.request('onShopCartChangeProductCount', {
        method: 'POST',
        loading: $.oc.stripeLoadIndicator,
        data: {
            product_id: productId,
            modification_id: modificationId,
            count
        }
    });
}